<template>
  <div
    :style="{
      backgroundColor: editKind != 0 ? '#F1F5F8' : 'white',
      height: editKind != 0 ? '100vh' : 'auto',
      padding: editKind != 0 ? '0 0 3.5rem 0' : '0 0 1rem 0',
    }"
  >
    <div
      :style="{
        padding: editKind != 0 ? '1rem 1rem 0.5rem 1rem' : '1rem 1rem 0 1rem',
      }"
      style="
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        background-color: white;
      "
    >
      <div style="display: flex; flex-flow: row wrap; align-items: center">
        <img
          style="width: 10px; height: auto"
          src="../assets/common-01.png"
          @click="goBack"
        />
        <span style="margin-left: 1rem; font-size: 1rem; font-weight: bold">{{
          $t("device.item_01")
        }}</span>
      </div>

      <div v-if="hasAcc">
        <q-icon
          name="mdi-square-edit-outline"
          size="25px"
          v-if="editKind === 0"
          @click="edit"
        />
        <span style="font-size: 1rem" v-if="editKind === 1" @click="edit">{{
          $t("device.item_05")
        }}</span>
        <span style="font-size: 1rem" v-if="editKind === 2" @click="edit">{{
          $t("device.item_06")
        }}</span>
      </div>
    </div>

    <div
      style="margin: 1rem 1rem 0 1rem; position: relative; overflow-x: hidden"
      v-for="(item, index) in list"
      :key="index"
    >
      <img
        :style="{ left: editKind != 0 ? '-50px' : '0' }"
        style="
          position: relative;
          width: 100%;
          height: auto;
          vertical-align: bottom;
        "
        :src="switchImg(index)"
      />
      <q-checkbox
        style="
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          right: 0;
        "
        v-if="editKind != 0"
        v-model="item.select"
        @click="editCheck"
      />
      <div
        @click="setDev(item.imei)"
        :style="{ left: editKind != 0 ? '-50px' : '0' }"
        style="
          position: absolute;
          width: 100%;
          top: 50%;
          transform: translate(0, -50%);
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          overflow-x: hidden;
        "
      >
        <img
          :style="{ left: editKind != 0 ? '50px' : '0' }"
          style="position: relative; width: 30%; height: auto"
          src="../assets/device-04.png"
        />
        <div
          :style="{ left: editKind != 0 ? '50px' : '0' }"
          style="position: relative; width: 70%; color: #fff"
        >
          <p style="margin: 0; font-size: 1rem">IMEI:</p>
          <p style="margin: 0; font-size: 1rem">{{ item.imei }}</p>
          <p style="margin: 0; font-size: 0.7rem">
            <span style="margin-right: 0.5rem"
              >{{ $t("device.item_02") }}:</span
            >
            <span style="color: #00ffff" v-if="item.status === 0">{{
              $t("device.item_03")
            }}</span>
            <span style="color: #ff7c00" v-else>{{
              $t("device.item_04")
            }}</span>
          </p>
        </div>
      </div>
    </div>

    <q-card
      style="position: relative; margin: 1rem 1rem 0 1rem; border-radius: 15px"
      v-if="hasAcc && editKind === 0"
      @click="goQuick"
    >
      <img
        style="
          width: 100%;
          height: auto;
          vertical-align: bottom;
          visibility: hidden;
        "
        src="../assets/device-01.png"
      />
      <div
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          flex-flow: row wrap;
          align-items: center;
        "
      >
        <img style="width: 30px; height: auto" src="../assets/home-07.png" />
        <span style="margin-left: 0.5rem; font-size: 1rem; font-weight: bold">{{
          $t("home.item_06")
        }}</span>
      </div>
    </q-card>
    <div
      style="
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: 1rem 0;
        text-align: center;
        color: red;
        background-color: white;
        font-size: 1rem;
      "
      v-if="hasAcc && editKind != 0"
      @click="deleteDev"
    >
      <span style="margin: 0 0.5rem 0 0">{{ $t("device.item_07") }}</span
      ><span>({{ editShow() }})</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      httpProcess: false,
      hasAcc: false,
      editKind: 0,
      list: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goQuick() {
      window.location.href = "/quick?code=bind";
    },
    switchImg(num) {
      switch (num % 3) {
        case 0:
          return require("../assets/device-01.png");
        case 1:
          return require("../assets/device-02.png");
        default:
          return require("../assets/device-03.png");
      }
    },
    editCheck() {
      for (var i = 0; i < this.list.length; i++) {
        if (!this.list[i].select) {
          this.editKind = 1;
          return;
        }
      }
      this.editKind = 2;
    },
    editShow() {
      var num = 0;
      for (var i = 0; i < this.list.length; i++) {
        if (this.list[i].select) {
          num = num + 1;
        }
      }
      return num;
    },
    edit() {
      if (this.editKind == 0) {
        this.editCheck();
      } else if (this.editKind == 1) {
        for (var i = 0; i < this.list.length; i++) {
          if (!this.list[i].select) {
            this.list[i].select = true;
          }
        }
        this.editKind = 2;
      } else if (this.editKind == 2) {
        for (i = 0; i < this.list.length; i++) {
          if (this.list[i].select) {
            this.list[i].select = false;
          }
        }
        this.editKind = 0;
      }
    },
    getData() {
      var self = this;
      if (self.httpProcess) return;
      self.httpProcess = true;

      self
        .$axios({
          method: "post",
          url: "/api/query/base/info",
        })
        .then((res) => {
          self.httpProcess = false;
          if (res.data.user) self.hasAcc = true;
          if (res.data.list && res.data.list.length) {
            self.list = res.data.list;
            for (var i = 0; i < self.list.length; i++) {
              self.list[i].select = false;
            }
          }
        })
        .catch(function (error) {
          self.httpProcess = false;
          var msg;
          switch (error.response.data.code) {
            case 401:
              msg = self.$t("common.item_01");
              break;
            default:
              msg = self.$t("common.item_02");
          }
          self.$q.dialog({
            message: msg,
            ok: self.$t("common.item_03"),
          });
        });
    },
    setDev(imei) {
      var self = this;
      if (self.httpProcess) return;
      self.httpProcess = true;

      self
        .$axios({
          method: "post",
          url: "/api/set/imei",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          params: { imei: imei },
        })
        .then(() => {
          self.httpProcess = false;
          self.$router.push({ path: "/", query: { mark: "imei" } });
        })
        .catch(function (error) {
          self.httpProcess = false;
          var msg;
          switch (error.response.data.code) {
            case 401:
              msg = self.$t("common.item_01");
              break;
            default:
              msg = self.$t("common.item_02");
          }
          self.$q.dialog({
            message: msg,
            ok: self.$t("common.item_03"),
          });
        });
    },
    deleteDev() {
      var self = this;
      var idArr = [];
      for (var i = 0; i < self.list.length; i++) {
        if (self.list[i].select) {
          idArr.push(self.list[i].id);
        }
      }
      if (idArr.length == 0) return;
      var info = idArr.join(",");

      if (self.httpProcess) return;
      self.httpProcess = true;
      self
        .$axios({
          method: "post",
          url: "/api/delete/dev",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          params: {
            info: info,
          },
        })
        .then(() => {
          self.httpProcess = false;
          self.$router.go(0);
        })
        .catch(function (error) {
          self.httpProcess = false;
          var msg;
          switch (error.response.data.code) {
            case 401:
              msg = self.$t("common.item_01");
              break;
            default:
              msg = self.$t("common.item_02");
          }
          self.$q.dialog({
            message: msg,
            ok: self.$t("common.item_03"),
          });
        });
    },
  },
};
</script>
